
export const AppMenus = {
    
	navbarTopRightItems: [],
	navbarTopLeftItems: [],
	navbarSideLeftItems: [
  {
    "path": "/home",
    "label": "Home",
    "icon": "home",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/user",
    "label": "User",
    "icon": "contacts",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/ekin_tu",
    "label": "Ekin Tu",
    "icon": "person_add",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/roles",
    "label": "Roles",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/permissions",
    "label": "Permissions",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/s_abesn",
    "label": "S Abesn",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/s_master_nilai",
    "label": "S Master Nilai",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/ekin_guru",
    "label": "Ekin Guru",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/s_tampilan_depan/list_tampilan_tu",
    "label": "Tamptilan TU",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/s_tampilan_depan_penilai_tu/tampilan_depan_penilaian_tu",
    "label": "Tampilan Depan TU",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/s_tampilan_petugas_uks",
    "label": "S Tampilan Petugas Uks",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/s_tampilan_print_petugas_uks",
    "label": "S Tampilan Print Petugas Uks",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/s_tampilan_print_petugas_uks/list_tampilan_print",
    "label": "tampilan Print Petugas uks",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/s_tampilan_petugas_uks/list_tampilan_petugas_uks",
    "label": "tampilan Tambah Ekin",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/user/list_tampilan_foto",
    "label": "Ganti Foto",
    "icon": "photo_camera",
    "iconcolor": "",
    "target": "",
    "submenu": []
  }
],
	EkinGuruListHeaderItems: [
  {
    "label": "Id Ekin Guru",
    "align": "left",
    "sortable": false,
    "name": "id_ekin_guru",
    "field": "id_ekin_guru"
  },
  {
    "label": "Tanggal",
    "align": "left",
    "sortable": false,
    "name": "tanggal",
    "field": "tanggal"
  },
  {
    "label": "Nama Lengkap",
    "align": "left",
    "sortable": false,
    "name": "nama_lengkap",
    "field": "nama_lengkap"
  },
  {
    "label": "Jabatan",
    "align": "left",
    "sortable": false,
    "name": "jabatan",
    "field": "jabatan"
  },
  {
    "label": "Absen",
    "align": "left",
    "sortable": false,
    "name": "absen",
    "field": "absen"
  },
  {
    "label": "Kegiatan",
    "align": "left",
    "sortable": false,
    "name": "kegiatan",
    "field": "kegiatan"
  },
  {
    "label": "Tugas Tambahan",
    "align": "left",
    "sortable": false,
    "name": "tugas_tambahan",
    "field": "tugas_tambahan"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Photo",
    "align": "left",
    "sortable": false,
    "name": "photo",
    "field": "photo"
  },
  {
    "label": "Nilai",
    "align": "left",
    "sortable": false,
    "name": "nilai",
    "field": "nilai"
  },
  {
    "label": "Kategori",
    "align": "left",
    "sortable": false,
    "name": "kategori",
    "field": "kategori"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	EkinGuruListUserGuruHeaderItems: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	EkinTuListHeaderItems: [
  {
    "label": "Id Ekin Tu",
    "align": "left",
    "sortable": false,
    "name": "id_ekin_tu",
    "field": "id_ekin_tu"
  },
  {
    "label": "Tanggal",
    "align": "left",
    "sortable": false,
    "name": "tanggal",
    "field": "tanggal"
  },
  {
    "label": "Nama Lengkap",
    "align": "left",
    "sortable": false,
    "name": "nama_lengkap",
    "field": "nama_lengkap"
  },
  {
    "label": "Jabatan",
    "align": "left",
    "sortable": false,
    "name": "jabatan",
    "field": "jabatan"
  },
  {
    "label": "Absen",
    "align": "left",
    "sortable": false,
    "name": "absen",
    "field": "absen"
  },
  {
    "label": "Kegiatan",
    "align": "left",
    "sortable": false,
    "name": "kegiatan",
    "field": "kegiatan"
  },
  {
    "label": "Tugas Tambahan",
    "align": "left",
    "sortable": false,
    "name": "tugas_tambahan",
    "field": "tugas_tambahan"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Photo",
    "align": "left",
    "sortable": false,
    "name": "photo",
    "field": "photo"
  },
  {
    "label": "Nilai",
    "align": "left",
    "sortable": false,
    "name": "nilai",
    "field": "nilai"
  },
  {
    "label": "Kategori",
    "align": "left",
    "sortable": false,
    "name": "kategori",
    "field": "kategori"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	EkinTuListPenilaianTuHeaderItems: [
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	PermissionsListHeaderItems: [
  {
    "label": "Permission Id",
    "align": "left",
    "sortable": false,
    "name": "permission_id",
    "field": "permission_id"
  },
  {
    "label": "Permission",
    "align": "left",
    "sortable": false,
    "name": "permission",
    "field": "permission"
  },
  {
    "label": "Role Id",
    "align": "left",
    "sortable": false,
    "name": "role_id",
    "field": "role_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	RolesListHeaderItems: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "masterdetailbtn",
    "field": ""
  },
  {
    "label": "Role Id",
    "align": "left",
    "sortable": false,
    "name": "role_id",
    "field": "role_id"
  },
  {
    "label": "Role Name",
    "align": "left",
    "sortable": false,
    "name": "role_name",
    "field": "role_name"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	SAbesnListHeaderItems: [
  {
    "label": "Id Absen",
    "align": "left",
    "sortable": false,
    "name": "id_absen",
    "field": "id_absen"
  },
  {
    "label": "Nama",
    "align": "left",
    "sortable": false,
    "name": "nama",
    "field": "nama"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	SMasterNilaiListHeaderItems: [
  {
    "label": "Id S Master Nilai",
    "align": "left",
    "sortable": false,
    "name": "id_s_master_nilai",
    "field": "id_s_master_nilai"
  },
  {
    "label": "Nilai",
    "align": "left",
    "sortable": false,
    "name": "nilai",
    "field": "nilai"
  },
  {
    "label": "Kategori",
    "align": "left",
    "sortable": false,
    "name": "kategori",
    "field": "kategori"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Penilai",
    "align": "left",
    "sortable": false,
    "name": "penilai",
    "field": "penilai"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	STampilanPetugasUksListHeaderItems: [
  {
    "label": "Id S Tampilan Petugas Uks",
    "align": "left",
    "sortable": false,
    "name": "id_s_tampilan_petugas_uks",
    "field": "id_s_tampilan_petugas_uks"
  },
  {
    "label": "Nama",
    "align": "left",
    "sortable": false,
    "name": "nama",
    "field": "nama"
  },
  {
    "label": "Keterangan",
    "align": "left",
    "sortable": false,
    "name": "keterangan",
    "field": "keterangan"
  },
  {
    "label": "Photo",
    "align": "left",
    "sortable": false,
    "name": "photo",
    "field": "photo"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	STampilanPetugasUksListTampilanPetugasUksHeaderItems: [],
	STampilanPrintPetugasUksListHeaderItems: [
  {
    "label": "Id S Tampilan Print Petugas Uks",
    "align": "left",
    "sortable": false,
    "name": "id_s_tampilan_print_petugas_uks",
    "field": "id_s_tampilan_print_petugas_uks"
  },
  {
    "label": "Nama",
    "align": "left",
    "sortable": false,
    "name": "nama",
    "field": "nama"
  },
  {
    "label": "Keterangan",
    "align": "left",
    "sortable": false,
    "name": "keterangan",
    "field": "keterangan"
  },
  {
    "label": "Photo",
    "align": "left",
    "sortable": false,
    "name": "photo",
    "field": "photo"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	UserListHeaderItems: [
  {
    "label": "Id User",
    "align": "left",
    "sortable": false,
    "name": "id_user",
    "field": "id_user"
  },
  {
    "label": "User",
    "align": "left",
    "sortable": false,
    "name": "user",
    "field": "user"
  },
  {
    "label": "Nama Lengkap",
    "align": "left",
    "sortable": false,
    "name": "nama_lengkap",
    "field": "nama_lengkap"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Jabatan",
    "align": "left",
    "sortable": false,
    "name": "jabatan",
    "field": "jabatan"
  },
  {
    "label": "Telp",
    "align": "left",
    "sortable": false,
    "name": "telp",
    "field": "telp"
  },
  {
    "label": "Photo",
    "align": "left",
    "sortable": false,
    "name": "photo",
    "field": "photo"
  },
  {
    "label": "User Role Id",
    "align": "left",
    "sortable": false,
    "name": "user_role_id",
    "field": "user_role_id"
  },
  {
    "label": "Password",
    "align": "left",
    "sortable": false,
    "name": "password",
    "field": "password"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],

    exportFormats: {
        print: {
			label: 'Print',
			color: 'blue',
            icon: 'print',
            id: 'print',
            ext: '',
        },
        pdf: {
			label: 'Pdf',
			color: 'red',
            icon: 'picture_as_pdf',
            id: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
			color: 'green',
            icon: 'grid_on',
            id: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
			color: 'teal',
            icon: 'grid_on',
            id: 'csv',
            ext: 'csv',
        },
    },
    
}